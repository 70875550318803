import { navigate } from "gatsby";
import React, { Component } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

import PaymentForm from "./PaymentForm";
import StripeWrapper from "./StripeWrapper";
import CurrencyInput from "../Inputs/CurrencyInput";
import Input from "../Inputs/Input.input";
import { Button } from "../Inputs/Button.span";

const ClickableText = styled.span`
  color: ${theme.colors.brand[500]};
  font-weight: bold;
  cursor: pointer;
`;

const DonationExperienceWrapper = styled.div`
  width: 100%;
`;

const IntervalWrapper = styled.div`
  display: flex;
  margin-bottom: 1em;
  button {
    flex-grow: 1;
    max-width: 50%;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 1px;
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
`;

const PanelWrapper = styled.div`
  background: ${theme.colors.white["700"]};
  border: 1px solid ${theme.colors.white["500"]};
  border-radius: 0.25em;

  .panel__header {
    padding: 0.75em 1.5em;
    text-align: center;
    color: ${theme.colors.gray["100"]};
    font-size: 1.1em;
    font-weight: 500;
    border-bottom: 1px solid ${theme.colors.white["500"]};
  }

  .panel__body {
    padding: 1em 1.5em;
    background: ${theme.colors.white["900"]};
    input[type="number"] {
      color: ${theme.colors.brand["500"]};
      font-size: 1.5em;
    }
    .payment-info {
      input {
        margin-bottom: 0.75em;
      }
    }
  }

  .panel__body__action {
    display: flex;
    margin-top: 1em;
    button {
      flex-grow: 1;
      margin: 0;
      letter-spacing: 2px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
`;

class DonateExperienceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewIndex: 0,
      donationAmount: 60,
      donationInterval: "once",
      donorName: "",
      donorEmail: "",
    };
    this.onDonationAmountChanged = this.onDonationAmountChanged.bind(this);
    this.onIntervalChange = this.onIntervalChange.bind(this);
    this.updateHelper = this.updateHelper.bind(this);
  }

  onDonationAmountChanged(event) {
    const donationAmount = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      donationAmount: donationAmount >= 0 ? donationAmount : 0,
    }));
  }

  onIntervalChange(interval) {
    if (interval === "once") {
      this.setState((prevState) => ({ ...prevState, donationInterval: "once", donationAmount: 60, viewIndex: 0 }));
    } else if (interval === "month") {
      this.setState((prevState) => ({ ...prevState, donationInterval: "month", donationAmount: 60, viewIndex: 0 }));
    }
  }

  onPaymentSuccessful() {
    navigate(`/donations?amount=${this.state.donationAmount}`);
  }

  updateHelper(key, value) {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  render() {
    const { apiURL, stripeKey } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <script id="stripe-js" src="https://js.stripe.com/v3/" async></script>
        </Helmet>
        <DonationExperienceWrapper>
          <IntervalWrapper>
            <Button
              buttonType={this.props.ui === "light" ? "white" : "brand"}
              className={this.state.donationInterval === "once" ? "active" : ""}
              inverted={this.state.donationInterval === "month"}
              onClick={() => this.onIntervalChange("once")}
            >
              Give Once
            </Button>
            <Button
              buttonType={this.props.ui === "light" ? "white" : "brand"}
              className={this.state.donationInterval === "month" ? "active" : ""}
              inverted={this.state.donationInterval === "once"}
              onClick={() => this.onIntervalChange("month")}
            >
              Monthly
            </Button>
          </IntervalWrapper>

          <PanelWrapper>
            <div className="panel__header">
              {this.state.viewIndex === 0 && (
                <span>
                  {this.state.donationInterval === "once"
                    ? "Enter an amount to give"
                    : "Enter an amount to give per month"}
                </span>
              )}
              {this.state.viewIndex === 1 && (
                <span>
                  {this.state.donationInterval === "once"
                    ? `Giving $${this.state.donationAmount}`
                    : `Giving $${this.state.donationAmount} per month`}
                  &ensp;
                  <ClickableText onClick={() => this.setState((prevState) => ({ ...prevState, viewIndex: 0 }))}>
                    (edit amount)
                  </ClickableText>
                </span>
              )}
            </div>

            <div className="panel__body">
              {this.state.viewIndex === 0 && (
                <React.Fragment>
                  <CurrencyInput
                    size="lg"
                    type="number"
                    value={this.state.donationAmount}
                    currencyCode={this.state.donationInterval === "once" ? "USD" : "USD/MONTH"}
                    currencySymbol="$"
                    onChange={this.onDonationAmountChanged}
                  />
                  <div className="panel__body__action">
                    <Button size="lg" onClick={() => this.setState((prevState) => ({ ...prevState, viewIndex: 1 }))}>
                      {this.state.donationInterval === "once" ? "Donate" : "Donate Monthly"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {this.state.viewIndex === 1 && (
                <React.Fragment>
                  <StripeWrapper stripeKey={stripeKey}>
                    <div className="payment-info">
                      <Input
                        type="text"
                        placeholder="Name on Card"
                        defaultValue={this.state.donorName}
                        onChange={(e) => this.updateHelper("donorName", e.target.value)}
                      />
                      <Input
                        type="email"
                        placeholder="Email"
                        defaultValue={this.state.donorEmail}
                        onChange={(e) => this.updateHelper("donorEmail", e.target.value)}
                      />
                      <PaymentForm
                        apiURL={apiURL}
                        amount={this.state.donationAmount}
                        interval={this.state.donationInterval}
                        donorName={this.state.donorName}
                        donorEmail={this.state.donorEmail}
                        onPaymentSuccessful={() => this.onPaymentSuccessful()}
                      />
                    </div>
                  </StripeWrapper>
                </React.Fragment>
              )}
            </div>
          </PanelWrapper>
        </DonationExperienceWrapper>
      </React.Fragment>
    );
  }
}

export default DonateExperienceComponent;
