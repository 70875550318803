import React from "react";
import styled from "styled-components";
import Input from "./Input.input";

const Container = styled.div`
  position: relative;
  input {
    outline: 0;
    position: relative;
    z-index: 1;
    padding-left: 1.25em;
  }
  span {
    color: ${(props) => props.theme.colors.gray["500"]};
  }
`;

const Wrapper = styled.div`
  label {
    margin-bottom: 0.5em;
    display: block;
  }
`;

const CurrencySymbol = styled.span`
  position: absolute;
  top: 48%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.6em;
  font-size: 1.25em;
  font-weight: 500;
`;

const CurrencyCode = styled.span`
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.5em;
  font-weight: 500;
`;

// Controlled form component. Requires an `onChange` handler and `value` to be passed in by consumers
const CurrencyInput = ({ label, currencySymbol, currencyCode, onChange, placeholder, value, size }) => (
  <Wrapper>
    {label && <label>{label}</label>}
    <Container>
      {currencySymbol && <CurrencySymbol>{currencySymbol}</CurrencySymbol>}
      <Input size={size} type="number" step="1.0" onChange={onChange} placeholder={placeholder} value={value} />
      {currencyCode && <CurrencyCode>{currencyCode}</CurrencyCode>}
    </Container>
  </Wrapper>
);

export default CurrencyInput;
