import axios from "axios";

export default function submitDonation(apiURL, token, amount, interval, donorName, donorEmail) {
  const amountInCents = Number(amount) * 100;
  return axios.post(`${apiURL}/v1/donations`, {
    token,
    amount: amountInCents,
    interval,
    donor: {
      name: donorName,
      email: donorEmail,
    },
  });
}
