import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";

// Wraps stripe.js ensuring it's loaded and initialized properly
export default class StripeWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
  }

  componentDidMount() {
    const { stripeKey } = this.props;
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({ stripe: window.Stripe(stripeKey) });
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>{this.props.children}</Elements>
      </StripeProvider>
    );
  }
}
