import React, { Component } from "react";
import styled from "styled-components";
import { get } from "lodash";
import { graphql } from "gatsby";
import { theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";

import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import DonateExperienceComponent from "../components/Donations/DonateExperienceComponent";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import P from "../components/Type/Paragraph.p";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { H2 } from "../components/Type/Display.h";

const HeroWithHeader = styled.div`
  background: ${theme.colors.brand["500"]} url(${require("../../static/images/user-wall.jpg")});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  @media screen and (max-width: 45em) {
    background-position: top 0 right -240px;
  }
  & > .hero {
    padding: 6em 4em 10em;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1em 0;
      margin: 0 auto;
      max-width: 920px;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 0;
        margin-right: 3em;
        h1,
        h4,
        p {
          color: white;
        }
      }
      & > div:not(.text) {
        width: 400px;
        min-width: 400px;
      }
      @media screen and (max-width: 45em) {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        .text {
          padding: 0;
          margin: 0;
          margin-bottom: 1em;
        }
        & > div:not(.text) {
          width: 100%;
          min-width: 100%;
        }
      }
    }
    @media screen and (max-width: 45em) {
      padding: 1em 1em 8em;
    }
  }
`;

const contentGroup = CONTENT_GROUPS.DONATIONS_PAGE;
const templateProps = {
  pageComponentName: "DonatePage",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

export default class DonatePage extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const stripeKey = get(this.props, "data.site.siteMetadata.env.STRIPE_KEY");
    const apiURL = get(this.props, "data.site.siteMetadata.env.UPSOLVE_API_URL");
    return (
      <TopLevelLayout>
        <div>
          <SEO
            title="Donate - Give the Gift of a Fresh Start | Upsolve"
            description="Donate to Upsolve and join us in our mission to help low-income Americans recover from hardship and start new chapters of their lives."
            image="https://upsolve.org/images/meta-probono.png"
          />
          <HeroWithHeader>
            <MainHeader className="header" trackingPageContext={trackingPageContext} />
            <main className="hero">
              <div>
                <div className="text">
                  <H2 as="h1">Your money lifts Americans out of poverty.</H2>
                  <P>
                    A <b>$25</b> donation allows us to help a low-income American file for bankruptcy. Help end poverty,
                    inspire hope, and be the change of someone's life today.
                  </P>
                </div>
                <DonateExperienceComponent apiURL={apiURL} stripeKey={stripeKey} ui="light" />
              </div>
            </main>
          </HeroWithHeader>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        env {
          STRIPE_KEY
          UPSOLVE_API_URL
        }
      }
    }
  }
`;
