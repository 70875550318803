import React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled, { createGlobalStyle } from "styled-components";
import { theme } from "@upsolve/ui";

import { Button } from "../Inputs/Button.span";
import submitDonation from "./submitDonation";

const StripeCSS = createGlobalStyle`
  .StripeElement {
    width: 100%;
    outline: none;
    padding: .8em 1em;
    font-size: 0.9em;
    border: 1px solid ${theme.colors.white["500"]};
    border-bottom: 2px solid ${theme.colors.white["300"]};
    border-radius: 4px;
    padding: .8em .5em;
    font-size: 1em;
  }
`;

const SubmitContainer = styled.div`
  margin-top: 2em;
  button {
    width: 100%;
  }
`;

const ErrorContainer = styled.div`
  margin-top: 1em;
  padding: 1em;
  margin-bottom: 1em;
  color:  ${(props) => props.theme.colors.white[500]}
  background-color: #d56767;
`;
// Stripe's supported CSS options for the credit card component
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "1em",
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
    },
  };
};

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      processing: false,
    };
  }

  onSubmit(ev) {
    const { apiURL } = this.props;
    ev.preventDefault();

    this.setState({ processing: true });
    this.props.stripe
      .createToken()
      .then(({ token, error }) => {
        if (error) throw Error(error.message);
        const cleanInterval = this.props.interval === "month" ? "month" : null;
        return submitDonation(
          apiURL,
          token.id,
          this.props.amount,
          cleanInterval,
          this.props.donorName,
          this.props.donorEmail
        ).then(() => {
          if (this.props.onPaymentSuccessful) {
            this.props.onPaymentSuccessful();
          }
        });
      })
      .catch((err) => {
        this.setState({ processing: false, errored: true });
      });
  }

  render() {
    const { processing } = this.state;
    return (
      <React.Fragment>
        <StripeCSS />
        <form onSubmit={this.onSubmit}>
          {this.props.errored && (
            <ErrorContainer>
              We've encountered an error submitting your payment. Please check the information below and try again.
            </ErrorContainer>
          )}
          <CardElement {...createOptions()} />
          <SubmitContainer>
            <Button disabled={processing}>{processing === true ? "Processing" : `Next Step`}</Button>
          </SubmitContainer>
        </form>
      </React.Fragment>
    );
  }
}

export default injectStripe(PaymentForm);
